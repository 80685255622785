<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card no-body title="Achievements List">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th>Title</th>
                  <th class="text-center">Number</th>
                  <th class="text-center">Type</th>
                  <th class="text-center">Status</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(achievement, index) in achievements.data"
                  :key="index"
                  v-if="achievements.data.length"
                >
                  <td class="align-middle text-center">
                    {{ achievements.from + index }}
                  </td>
                  <td class="align-middle">
                    <b>{{ achievement.title }}</b>
                  </td>
                  <td class="align-middle text-center">
                    {{ achievement.number }}
                  </td>
                  <td class="align-middle text-center">
                    {{
                      achievement.type == 1
                        ? "Long Content"
                        : achievement.type == 2
                        ? "Short Content"
                        : "CIT Initiatives"
                    }}
                  </td>
                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      :class="
                        achievement.status == 1
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                    >
                      {{ achievement.status == 1 ? "Active" : "Inactive" }}
                    </span>
                  </td>
                  <td class="align-middle text-center" v-if="permission.edit">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="edit(achievement.id)"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ achievements.from ? achievements.from : 0 }} to
                  {{ achievements.to ? achievements.to : 0 }} of
                  {{ achievements.total ? achievements.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="achievements"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card
          :title="(form.id == '' ? 'Create' : 'Update') + ' Cit Achievement'"
        >
          <b-card-text
            ><validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Select Type" label-for="type">
                      <validation-provider
                        #default="{ errors }"
                        name="type"
                        rules="required"
                      >
                        <v-select
                          id="type"
                          v-model="form.type"
                          placeholder="Select Type"
                          :options="types"
                          label="name"
                          :reduce="(option) => option.id"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Title" label-for="title">
                      <validation-provider
                        #default="{ errors }"
                        name="title"
                        rules="required"
                      >
                        <b-form-input
                          id="title"
                          v-model="form.title"
                          :state="errors.length > 0 ? false : null"
                          name="title"
                          placeholder="Enter Your Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Number" label-for="number">
                      <validation-provider
                        #default="{ errors }"
                        name="number"
                        rules="required"
                      >
                        <b-form-input
                          id="number"
                          v-model="form.number"
                          :state="errors.length > 0 ? false : null"
                          name="number"
                          placeholder="Enter Your Number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12" v-if="form.type == 1">
                    <div class="d-block" style="height: 350px">
                      <b-form-group label="Description" label-for="description">
                        <validation-provider
                          #default="{ errors }"
                          name="description"
                          rules="required"
                        >
                          <quill-editor
                            id="description"
                            style="height: 220px"
                            class="mb-4"
                            v-model="form.description"
                            name="description"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Your Description"
                          />
                          <div style="clear: both"></div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <!-- submit buttons -->
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer></b-card-text
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import _ from "lodash";

import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],

      types: [
        { name: "Long Content", id: 1 },
        { name: "Short Content", id: 2 },
        { name: "CIT Initiatives", id: 3 },
      ],
      form: {
        id: "",
        type: null,
        title: "",
        number: "",
        description: "",
        status: true,
      },
      icon_image: this.$store.state.base_url + "images/department/default.png",
      achievements: {},
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.Permission();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const achievements = await this.callApi(
        "post",
        "/app/cit/achievement?page=" + this.search.page,
        this.search
      );
      if (achievements.status == 200) {
        this.achievements = achievements.data.achievements;
      }
    },

    Close() {
      this.form.id = "";
      this.form.type = null;
      this.form.title = "";
      this.form.number = "";
      this.form.description = "";
      this.form.status = true;
    },

    async edit(id) {
      const achievement = await this.callApi(
        "post",
        "/app/cit/achievement/show/" + id
      );
      if (achievement.status == 200) {
        this.form.id = achievement.data.achievement.id;
        this.form.type = achievement.data.achievement.type;
        this.form.title = achievement.data.achievement.title;
        this.form.number = achievement.data.achievement.number;
        this.form.description = achievement.data.achievement.description;
        this.form.status =
          achievement.data.achievement.status == true ? true : false;
      }
    },

    add() {
      axios
        .post("/app/cit/achievement/store", this.form)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.type)
              this.e(e.response.data.errors.type[0]);
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.number)
              this.e(e.response.data.errors.number[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
            if (e.response.data.errors.status)
              this.e(e.response.data.errors.status[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "cit.achievement.access") {
          this.permission.access = true;
        }

        if (permission.slug == "cit.achievement.create") {
          this.permission.create = true;
        }

        if (permission.slug == "cit.achievement.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "cit.achievement.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  components: {
    quillEditor,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
